// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;

	@include media-breakpoint-up(sm) {
		min-height: 350px;
	}

	@include media-breakpoint-up(xl) {
		min-height: 540px;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 80vh;
	min-height: 350px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
		min-height: 540px;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 10px;
		background: url('/images/waves-cream-top.svg');
	}

	// owl-carousel
	.owl-carousel {
		.item {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(0deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
			}

			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					.owl-caption {
						color: $white;
						text-shadow: none;

						.owl-title {
							margin-bottom: 1.5rem;
							font-size: 36px;
							font-weight: 300;
							font-family: $font-family-tertiary;

							@include media-breakpoint-up(xl) {
								font-size: 78px;
							}
						}
					}
				}
			}
		}

		// owl-nav
		.owl-nav {
			display: none;
		}

		// owl-dots
		.owl-dots {
			bottom: 20px;

			.owl-dot {
				span {
					width: 24px;
					height: 24px;
					margin: 5px;
					border-width: 1px;
					border-radius: 100%;
					box-shadow: none;

					@include media-breakpoint-down(sm) {
						width: 16px;
						height: 16px;
						margin: 2.5px;
					}
				}

				&:hover,
				&.active {
					span {
						background: transparent;
						border-color: $blue;
					}
				}
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: absolute;
		top: 30px;
		left: 0;
		right: 0;
		width: 150px;
		margin: 0 auto;
		transition: 0.5s;

		@include media-breakpoint-down(sm) {
			top: 15px;
			width: 100px;
		}

		@include media-breakpoint-only(md) {
			width: 125px;
		}

		a {
			display: block;

			img {
				@extend .w-100;
			}
		}
	}
}
