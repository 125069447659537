// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	padding: 6vh 0;
	background-color: $cream;
	color: $brown;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 10px;
		background: url('/images/waves-cream-bottom.svg');
	}

	.container-one-column:first-of-type {
		@extend .container-large;

		.container-holder {
			text-align: center;
		}
	}

	// wysiwyg
	.wysiwyg {

		h4,
		h5,
		h6 {
			max-width: 680px;
			margin-left: auto;
			margin-right: auto;
			line-height: 1.75;

			@include media-breakpoint-down(sm) {
				font-size: 16px;
			}
		}
	}

	// icon-collection
	.icon-collection {
		margin-top: 30px;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	@media (min-width: 1300px) {
		.container {
			max-width: 1270px;
		}
	}

	// collection
	.collection {
		@media (min-width: 1300px) {
			&.grid {
				.grid-items {
					margin-left: -10px;
					margin-right: -10px;

					.item {
						padding-left: 10px;
						padding-right: 10px;
					}
				}
			}
		}

		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-collection-section
// =========================================================================
.banner-collection-section {
	margin: 6vh 0;

	// wysiwyg
	.wysiwyg {
		overflow: hidden;

		h1,
		h2 {
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;

			&::before {
				content: '';
				position: absolute;
				left: -170px;
				width: 150px;
				height: 1px;
				background-color: $green;
			}

			&::after {
				content: '';
				position: absolute;
				right: -170px;
				width: 150px;
				height: 1px;
				background-color: $green;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 320px;
		max-height: 640px;
		margin-bottom: 80px;

		.owl-stage-outer {
			padding-bottom: 80px;
		}

		.item {
			position: relative;
			height: 75vh !important; // overide
			min-height: 320px;
			max-height: 640px;
			padding: unset; // overide
			align-items: flex-end;

			.owl-container {
				z-index: 100;
				position: relative;

				.owl-caption {
					max-width: 520px;
					margin: 0 0 -80px auto;
					padding: 30px;
					background-color: $brown;
					border-radius: 4px;
					color: $white;
					text-shadow: none;
					text-align: left;

					@include media-breakpoint-up(lg) {
						margin-right: 35px;
					}

					.owl-title {
						font-size: 24px;
					}

					.owl-subtitle {
						font-size: 16px;
					}

					.owl-description {
						.list {
							margin-bottom: 1rem;
						}
					}

					.owl-btn {
						@extend .link-arrow;
						padding: 0 20px 0 0;
						border: none;
						background: transparent;
						color: $white;
						text-decoration: underline;
					}
				}
			}
		}

		// owl-nav
		.owl-nav {
			position: static;

			button {
				position: absolute;
				transform: translateY(-50%);
				color: $white;
				text-shadow: none;

				@include media-breakpoint-up(xl) {
					width: auto;
					color: $green;

					&.owl-prev {
						left: -45px;
					}

					&.owl-next {
						right: -45px;
					}
				}

				@media (min-width: 1366px) {
					&.owl-prev {
						left: -100px;
					}

					&.owl-next {
						right: -100px;
					}
				}

				&:hover {
					transform: translateY(-50%);
				}
			}
		}

		// owl-dots
		.owl-dots {
			display: none;
		}
	}
}

// special-collection-section
// =========================================================================
.special-collection-section {
	margin: 6vh 0;

	@media (min-width: 1300px) {
		.container {
			max-width: 1270px;
		}
	}

	// collection
	.collection {
		&.owl-carousel {
			.owl-stage-outer {
				padding-top: 10px;

				.owl-item {
					.item {
						transition: 0.5s;
					}

					&:not(:first-of-type) {
						.item {
							@include media-breakpoint-up(xl) {
								margin-left: -20px;
							}
						}
					}

					&:nth-child(even) {
						.item {
							margin-top: 90px;

							@include media-breakpoint-down(lg) {
								margin-top: 40px;
							}
						}
					}

					&:nth-child(3n+1) {
						.item {
							.card {
								background-color: $green;

								&:hover {
									background-color: lighten($green, 5%);
								}
							}
						}
					}

					&:nth-child(3n+2) {
						.item {
							.card {
								background-color: $blue;

								&:hover {
									background-color: lighten($blue, 5%);
								}
							}
						}
					}

					&:nth-child(3n+3) {
						.item {
							.card {
								background-color: $brown;

								&:hover {
									background-color: lighten($brown, 5%);
								}
							}
						}
					}

					&:hover {
						.item {
							transform: translateY(-10px);
						}
					}

					.item {
						height: auto !important;

						.card {
							flex-direction: row-reverse;
							min-height: 250px;
							padding-right: 30px;
							border: none;
							border-radius: 4px;
							transition: 0.5s;

							.card-image {
								margin: 25px 0 0 0;

								@include media-breakpoint-down(xs) {
									display: none;
								}

								i {
									color: $white;
									font-size: 80px;
									max-width: 135px;
									max-height: 135px;
								}

								img {
									max-width: 135px;
									max-height: 135px;
								}
							}

							.card-body {
								padding: 20px;

								.card-caption {
									color: $white;

									.card-title-link {
										color: $white;

										.card-title {
											font-size: 24px;
											word-break: break-word;

											&:hover {
												color: inherit;
											}
										}
									}

									.card-subtitle {
										margin: 0;
										font-size: 14px;
										font-weight: 700;
									}

									.card-description {
										p {
											margin-bottom: 0;
										}

										a {
											color: $white;
										}
									}
								}

								.card-buttons {
									.card-btn {
										position: relative;
										background: none;
										border: none;
										padding: 0 20px 0 0;
										font-size: 14px;
										text-decoration: underline;

										&::after {
											content: "\f061";
											position: absolute;
											right: 0;
											font-weight: 400;
											font-family: $font-awesome;
											transition: 0.5s;
										}

										&:hover {
											text-decoration: none;

											&::after {
												right: -5px;
											}
										}

										&:focus {
											box-shadow: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// custom
	&.custom {
		.collection {
			&.owl-carousel {
				.owl-stage-outer {
					.owl-item {
						&:nth-child(3n+1) {
							.item {
								.card {
									background-color: $brown;

									&:hover {
										background-color: lighten($brown, 5%);
									}
								}
							}
						}

						&:nth-child(3n+3) {
							.item {
								.card {
									background-color: $green;

									&:hover {
										background-color: lighten($green, 5%);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// quotes-section
// =========================================================================
.quotes-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.owl-carousel {

			.item {
				.card {
					width: 100%;
					background: none;
					border: none;

					.card-image {
						display: block;
					}

					.card-body {
						padding: 0;

						.card-caption {
							display: flex;
							flex-direction: column;
							align-items: center;
							text-align: center;

							.card-title {
								order: 1;
								position: relative;
								display: flex;
								align-items: center;
								justify-content: center;
								margin: 0;
								color: $blue;
								font-size: 48px;
								line-height: 1;
								font-family: $font-awesome;

								&::before {
									content: '';
									position: absolute;
									left: -170px;
									width: 150px;
									height: 1px;
									background-color: $green;
								}

								&::after {
									content: '';
									position: absolute;
									right: -170px;
									width: 150px;
									height: 1px;
									background-color: $green;
								}
							}

							.card-description {
								order: 2;
								margin: 10px 0 25px 0;
								font-size: 24px;
								line-height: 1.3;
								font-family: $font-family-secondary;

								@include media-breakpoint-down(md) {
									font-size: 20px;
								}

								p {
									margin-bottom: 0;
								}
							}

							.card-subtitle {
								order: 3;
								position: relative;
								display: flex;
								align-items: center;
								justify-content: center;
								margin: 0;
								font-size: 14px;
								font-weight: 700;
								font-family: $font-family-primary;

								@include media-breakpoint-down(md) {
									font-size: 13px;
								}

								&::before {
									content: '';
									position: absolute;
									left: -155px;
									width: 140px;
									height: 1px;
									background-color: $green;
								}

								&::after {
									content: '';
									position: absolute;
									right: -155px;
									width: 140px;
									height: 1px;
									background-color: $green;
								}
							}

						}

						.card-buttons {
							display: none;
						}
					}
				}
			}

			// owl-dots
			.owl-dots {
				position: static;
				margin-top: 5px;

				.owl-dot {
					span {
						width: 14px;
						height: 14px;
						margin: 2.5px;
						border-width: 1px;
						border-radius: 100%;
						box-shadow: none;
					}

					&:hover,
					&.active {
						span {
							background: $blue;
						}
					}
				}
			}
		}
	}
}

// tiles-collection-section
// =========================================================================
.tiles-collection-section {
	position: relative;
	padding: 6vh 0;
	background-color: $cream;
	color: $brown;

	&::before {
		content: '';
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 10px;
		background: url('/images/waves-cream-top.svg');
	}

	&::after {
		content: '';
		z-index: 100;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 10px;
		background: url('/images/waves-cream-bottom.svg');
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					&:nth-child(5n+1),
					&:nth-child(5n+2),
					&:nth-child(5n+3) {
						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}

						@include media-breakpoint-up(xl) {
							@include make-col(4);
						}
					}
				}
			}
		}

		.card {
			border: none;
			border-radius: 0;

			&::before {
				content: "";
				z-index: 1;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
			}

			.card-img-overlay {
				z-index: 2;
				display: flex;
				flex-direction: column;
				padding: 20px;
				background: transparent;
				transition: 0.5s;

				.card-caption {
					display: flex;
					flex-direction: column;
					flex: 0;
					margin-top: auto;

					.card-title {
						position: relative;
						order: 4;
						margin-bottom: 0;
						font-size: 20px;
						text-shadow: none;

						&::after {
							content: "\f061";
							position: absolute;
							right: 0;
							font-size: 18px;
							font-weight: 300;
							font-family: $font-awesome;
							transition: 0.5s;
						}

						&:hover {
							color: $white;
						}
					}

					.card-subtitle {
						font-size: 16px;
					}

					.card-subtitle,
					.card-description {
						height: 0;
						margin: 0;
						text-shadow: none;
						overflow: hidden;
						transition: 0.5s;
					}
				}

				.card-buttons {
					display: none;

					@include media-breakpoint-up(md) {
						height: 0;
						margin: 0;
						overflow: hidden;
						transition: 0.5s;
					}

					.card-btn {
						@extend .link-arrow;
						padding: 0 20px 0 0;
						border: none;
						background: transparent;
						color: $white;
						text-decoration: underline;
					}
				}
			}

			&:hover {
				.card-img-overlay {
					background: $blue;

					.card-caption {

						.card-subtitle,
						.card-description {
							height: auto;
						}

						.card-description {
							margin-bottom: 30px;
						}
					}

					.card-buttons {
						height: auto;
					}
				}
			}
		}

		+.wysiwyg {
			margin-top: 30px;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 300px;
		max-height: 700px;

		.item {
			position: relative;
			height: 75vh !important; // overide
			min-height: 300px;
			max-height: 700px;
			padding: unset; // overide

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.25);
			}

			.owl-container {
				z-index: 100;
				position: relative;

				.owl-caption {
					text-shadow: none;

					.owl-title {
						@include font-size($h1-font-size);
						font-family: $font-family-tertiary;
					}

					.owl-btn {
						margin-top: 10px;
					}
				}
			}
		}

		// owl-dots
		.owl-dots {
			display: none;
		}

		// owl-nav
		.owl-nav {
			right: 40px;
			bottom: 20px;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin-bottom: -45px;

				.item {
					margin-bottom: 45px;
				}
			}
		}

		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
