// mini-sab
.mini-sab {
	background-color: $cream;

	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 10px 0;
			background-color: $blue;
			border-radius: 4px;
			box-shadow: 0 0 10px rgba($black, 0.15);

			.column {
				padding-left: 10px;
				padding-right: 10px;
			}

			@include media-breakpoint-down(md) {
				//margin-top: 15px;
			}

			@include media-breakpoint-up(lg) {
				//position: absolute;
				//left: auto;
				//right: auto;
				//bottom: 70px;
			}
		}

		.widget-btn {
			background-color: $green !important;
			border-color: $green !important;

			&:focus,
			&:hover {
				background-color: $brown !important;
				border-color: $brown !important;
			}
		}
	}
}

&.home {
	.mini-sab {
		.container {
			.container-holder {
				@include media-breakpoint-up(lg) {
					//bottom: 140px;
				}
			}
		}
	}
}
