& {
	font-size: 14px;
}

// container
.container {
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}

}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 0;

	.card-body {
		position: relative;
		padding: 15px 0 0 0;

		.card-caption {
			.card-title {
				position: relative;
				color: $blue;
				font-size: 20px;

				&::after {
					content: '';
					display: block;
					max-width: 200px;
					height: 1px;
					margin: 5px 0;
					background-color: $green;
				}
			}

			.card-subtitle {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 100%;
				display: flex;
				align-items: flex-end;
				height: 120px;
				margin-bottom: 0;
				padding: 15px;
				background: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
				color: $cream;
				font-size: $font-size-base;
				font-family: $font-family-base;
			}
		}
	}
}

// link-arrow
.link-arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-right: 20px;
	font-size: 14px;
	font-weight: 700;
	text-decoration: none;

	&::after {
		content: "\f061";
		position: absolute;
		right: 0;
		color: inherit;
		font-weight: 400;
		font-family: $font-awesome;
	}

	&:hover {
		transform: translateX(5px);
	}
}

// heading-underline
.heading-underline {
	position: relative;

	&::after {
		content: '';
		display: block;
		max-width: 200px;
		height: 1px;
		margin: 5px 0;
		background-color: $blue;
	}
}

// owl-nav
.owl-carousel {
	.owl-nav {
		z-index: 100;
		position: absolute;
		right: 15px;
		bottom: 15px;
		display: flex;

		.owl-prev,
		.owl-next {
			position: static;
			transform: none;
			width: 50px;
			font-size: 36px;
			text-shadow: none;

			@include media-breakpoint-down(xs) {
				width: 24px;
				font-size: 18px;
			}
		}

		.owl-prev:hover {
			transform: translateX(-3px);
		}

		.owl-next:hover {
			transform: translateX(3px);
		}
	}
}

// icon-collection
.icon-collection {
	&.grid {
		.grid-items {
			margin-bottom: -45px;

			.item {
				margin-bottom: 45px;

				@include media-breakpoint-down(xs) {
					@include make-col(6);
				}

				@include media-breakpoint-up(xl) {
					flex: 1 !important;
					max-width: none !important;
				}

				.card {
					background: none;
					border: none;
					border-radius: 0;

					.card-image {
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto 20px auto;
						color: $gray;

						i {
							font-size: 80px;
						}
					}

					.card-body {
						padding: 0;
						text-align: center;

						.card-caption {
							.card-title {
								color: $blue;
								font-size: 16px;
							}

							.card-subtitle {
								margin: 0;
								font-size: 14px;
							}

							.card-description {
								font-size: 14px;
								line-height: 1.15;

								p {
									margin-bottom: 0;
								}
							}
						}
					}

					&[href]:hover {
						transform: scale(1.05);

						.card-title {
							color: $brown;
						}
					}
				}
			}
		}
	}
}
