// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	padding: 8px 0;
	background: $cream;
	box-shadow: $shadow;
	transition: 0.5s;

	.container,
	.container-fluid {
		@include media-breakpoint-up(xl) {
			padding-left: 30px;
			padding-right: 30px;
		}

		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: 15px;

		.navbar-toggler {
			margin-left: -10px;
			padding: 0;
			border: none;
			color: $blue;
			transition: 0.5s;

			i {
				width: 40px;
				font-size: 24px;
				line-height: 40px;
				text-align: center;
				transition: transform 0.5s;
			}

			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);

					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				color: $brown;
			}
		}
	}

	// logo
	.logo {
		width: 76px;
		margin-right: auto;
		transition: 0.5s;

		a {
			display: block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-top: 5px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $brown;
						font-size: 15px;
						font-family: $font-family-secondary;

						&:hover {
							color: $blue;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 14px;

							.nav-link {
								color: $brown;

								&:hover {
									color: $blue;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $blue;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				width: 100%;
				justify-content: center;

				>.nav-item {
					margin: 0 5px;
					font-size: 15px;
					font-family: $font-family-secondary;

					>.nav-link {
						color: $brown;

						&:hover {
							color: $blue;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						background-color: $cream;
						border-color: $cream;

						&::before,
						&::after {
							border-bottom-color: $cream;
						}

						.nav-item {
							font-size: 14px;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $brown;

								&:hover {
									color: $blue;
								}
							}

							&.active {
								.nav-link {
									color: $blue;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	.book-button {
		a {
			@extend .btn;
			@extend .btn-primary;
			padding-top: 6px !important;
			padding-bottom: 6px !important;

			@include media-breakpoint-down(xs) {
				font-size: 12px !important;
				padding: 6px 0.75rem !important;
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 20px;

		.navbar-nav {
			&.language {
				.nav-item {
					font-size: 14px;

					.nav-link {
						display: flex;
						align-items: center;
						border: none;
						border-radius: 0;
						color: $blue;
						text-transform: uppercase;

						&::after {
							content: '\f107';
							font-family: $font-awesome;
							font-size: 12px;
							height: 17px;
							margin-left: 5px;
							border: none;
						}

						&:hover {
							box-shadow: none;
							color: $brown;
						}
					}

					.dropdown-menu {
						padding: 0;
						border-radius: 4px;
						background: $cream;
						font-size: 14px;
						color: $body-color;
						overflow: hidden;

						.dropdown-item {
							color: $body-color;
							transform: 0.5s;

							&:hover {
								background: $blue;
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}
