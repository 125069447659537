// footer
.footer {
	position: relative;
	margin-top: calc(8vh + 10px);

	&::before {
		content: '';
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 10px;
		background: url('/images/waves-cream-top.svg');
	}

	// footer-outro
	.footer-outro {
		padding: 50px 0 65px 0;
		background-color: $cream;
		color: $brown;

		@include media-breakpoint-down(sm) {
			padding-bottom: 100px;
			text-align: center;
		}

		.container-two-columns {
			.column {
				&.two {
					display: flex;
					flex-flow: row wrap;

					@include media-breakpoint-down(sm) {
						align-items: center;
						flex-direction: column;
					}
				}
			}
		}

		// footer-text
		.footer-text {
			p:last-of-type {
				margin-bottom: 0;
			}
		}

		// footer-link
		.footer-link {
			@include media-breakpoint-down(sm) {
				margin-bottom: 1rem;
			}

			@include media-breakpoint-up(md) {
				margin-right: 20px;

			}

			.link {
				@extend .btn;
				@extend .btn-white;
				padding: 6px 1.5rem !important;

				&:focus,
				&:hover {
					background-color: $green !important;
					border-color: $green !important;
					color: $white !important;
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			.list {
				.list-item {
					font-size: 24px;

					+li {
						margin-left: 15px;
					}

					.link {
						display: block;
						color: $blue;

						&:hover {
							color: $green;
						}
					}
				}
			}
		}
	}

	// footer-address
	.footer-address {
		background-color: $brown;
		color: $white;
		font-size: 14px;
		text-align: center;

		a {
			color: $white;

			&:hover {
				color: rgba($green, 25%);
				text-decoration: underline;
			}
		}

		.logo {
			margin: -50px 0 25px 0;
		}

		.footer-text-copyright {
			padding: 100px 0 15px 0;

			ul {
				display: flex;
				justify-content: center;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				li {
					@include media-breakpoint-up(md) {
						+li {
							&::before {
								content: '-';
								margin: 0 10px
							}
						}
					}

					a {
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
