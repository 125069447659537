// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$blue: #0096af;
$green: #afc17e;
$brown: #5e5b44;
$cream: #efece8;
$gray: #707070;

$white: #fff;
$black: #000;

// theme-colors
$primary: $blue;
$secondary: $green;

// body
$body-bg: $white;
$body-color: $gray;

// links
$link-color: $blue;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Radio+Canada:ital,wght@0,300..700;1,300..700&family=Roboto+Slab:wght@100..900&display=swap');
$font-family-primary: 'Radio Canada', sans-serif;
$font-family-secondary: 'Roboto Slab', serif;
$font-family-tertiary: 'Pacifico', serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.25; // 36px     	(40)
$h2-font-size: $font-size-base * 2; // 32px			(32)
$h3-font-size: $font-size-base * 1.75; // 28px		(28)
$h4-font-size: $font-size-base * 1.5; // 24px		(24)
$h5-font-size: $font-size-base * 1.25; // 20px		(20)
$h6-font-size: $font-size-base; // 16px				(16)

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $brown;

// contextual
$light: $cream;
$dark: $brown;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
